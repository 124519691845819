import "./splfilter.css";

import Union from "../../../../assets/Union.png";
import SortImg from "../../../../assets/Sort_down.png";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

function Head(props) {
  return (
    <div onClick={props.func} className="splfilter-head">
      <img src={props.img} alt="img" />
      <div className="splfilter-title">{props.title}</div>
    </div>
  );
}

function Radios(props) {
  return (
    <div className="df aic jcb radios-c">
      <label onClick={() => props.applySort(props.value)} htmlFor={props.id}>
        {props.id}
      </label>
      <div className="block-label">
        <input type="radio" id={props.id} value={props.value} name="sort" />
      </div>
    </div>
  );
}

export const SplFilter = ({ func2, applySort }) => {
  const [Sort, setSortActive] = useState(false);

  const SortFunc = () => {
    Sort ? setSortActive(false) : setSortActive(true);
  };

  return (
    <>
      <div className="splfilter-container">
        <Head func={SortFunc} img={SortImg} title={"Sort"} />
        <Head img={Union} title={"Filter"} func={func2} />
      </div>

      <div className={Sort ? "sort-box sb-a" : "sort-box"}>
        <div className="sort-overlay"></div>
        <div className="main-sort">
          <div className="sortbox-head df aic jcb">
            <div className="df aic">
              <img src={SortImg} alt="img" />
              <div className="sortbox-title">Sort</div>
            </div>
            <span>
              <CloseIcon onClick={SortFunc} />
            </span>
          </div>
          <div className="sortbox-radios">
            <select
              name="sort"
              onChange={(e) => applySort(e.target.value)}
              id="sort"
              className="form-control"
            >
              <option value="lowtohigh">Price Low to High</option>
              <option value="hightolow">Price High to Low</option>
              <option value="newest">Newest First</option>
              <option value="oldest">Oldest First</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
};
