import Product from "../Product/Product";
import ProductImg from "../../../../assets/product.png";
import "./slider.css";

// Import Swiper React components

import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Autoplay } from "swiper";

import "swiper/css/bundle";

// Import Swiper styles
import "swiper/css";
import ProductRelatedCard from "../Product/ProductRelatedCard";

function Slider({ data }) {
  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, Autoplay]}
      slidesPerView={4}
      className="products-slider"
      navigation={false}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      breakpoints={{
        350: {
          slidesPerView: 1,
        },
        550: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
        1150: {
          slidesPerView: 4,
        },
        1200: {
          slidesPerView: 4.5,
        },
      }}
    >
      {data.map((item, index) => {
        return (
          <SwiperSlide key={index}>
            <ProductRelatedCard
              img={item.url}
              description={item.description}
              name={item.name}
              id={item.id}
              redirect={`/product/${item.id}/seller`}
              slug={item.slug}
              price={item.max_price}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default Slider;
