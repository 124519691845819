import "./productdetails.css";
import Navbar from "../Components/Navbar/Navbar";
import SearchIcon from "@mui/icons-material/Search";
import StarIcon from "@mui/icons-material/Star";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Link, useParams } from "react-router-dom";
//
import * as React from "react";
import Pd from "../Components/Pd/Pd";
import Slider from "../Components/Slider/Slider";

import Cart from "../../../assets/Bag_fill.png";
import Img from "../../../assets/product.png";
import Img2 from "../../../assets/product2.png";
import Imginfo from "../../../assets/Information.svg";
import Footer from "../../landing_page/Footer";
import ProductDesc from "../Components/ProductDesc/ProductDesc";
import { useState } from "react";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SortIcon from "@mui/icons-material/Sort";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Checkbox } from "@mui/material";
import { baseUrl } from "../../../utils/baseUrl";
import { toast } from "react-toastify";

function Product_Details() {
  const [size, setSize] = React.useState("");
  const [data, setData] = useState({});
  const [related, setRelated] = useState(false);

  const { slug } = useParams();
  const fetchApi = async () => {
    const response = await fetch(`${baseUrl}/catalog/${slug}/show`, {
      headers: {
        Accept: "application/json",
      },
    });
    const result = await response.json();

    if (result.error) {
      toast.error(result.message);
      return;
    }
    setData(() => result.data.data);
    setRelated(() => result.data.related);
  };

  const handleChange = (event) => {
    setSize(event.target.value);
  };

  const [value, setValue] = React.useState(0);
  function Add() {
    setValue(value + 1);
  }
  function Sub() {
    setValue(value - 1);
  }
  const [Cost, setCost] = useState(0);

  const getInitialState = () => {
    const val = "0";
    return val;
  };
  const getInitialStateN = () => {
    const valN = "0";
    return valN;
  };

  const [val, setVal] = useState(getInitialState);
  const [valN, setValN] = useState(getInitialStateN);

  const hC = (e) => {
    setVal(e.target.value);
  };
  const hCN = (e) => {
    setValN(e.target.value);
  };

  useEffect((useEffect) => {
    $("#a25").click(function () {
      $(".tab1").show();
      $(".tab2").hide();
      $(".tab3").hide();
      $(".dfltCheck").removeClass("dfltCheck");
    });
    $("#a50").click(function () {
      $(".tab1").hide();
      $(".tab2").show();
      $(".tab3").hide();
      $(".dfltCheck").removeClass("dfltCheck");
    });
    $("#a75").click(function () {
      $(".tab1").hide();
      $(".tab2").hide();
      $(".tab3").show();
      $(".dfltCheck").removeClass("dfltCheck");
    });
  }, []);

  const [isShown, setIsShown] = useState(false);

  const [count, setCount] = useState(1);
  const maxGuests = 99;
  const minGuests = 0;

  const handleCount = (e) => {
    if (e > maxGuests) {
      setCount(maxGuests);
    } else if (e < minGuests) {
      setCount(minGuests);
    } else setCount(e);
  };

  const decrementCount = () => {
    if (count > minGuests) setCount(count - 1);
  };

  const incrementCount = () => {
    if (count < maxGuests) setCount(count + 1);
    else if (count > maxGuests) setCount(maxGuests);
  };

  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <>
      <Navbar />
      <section className="product-details-container">
        <div className="pd-divider">
          <div className="pd-left">
            <div>
              <Pd
                base_image={data.url}
                additional={data.additional_images || []}
              />
            </div>
          </div>
          <div className="pd-right">
            <div className="pd-right-details">
              <div className="product-name text-capitalize">{data.name}</div>

              <div className="product-rate-avail">
                {/*<div className="product-availibility">In Stock</div>*/}
                {/* <div className="product-rating">
                  <div className="rating-heading">Ratings</div>
                  <div className="stars-div">
                    <div className="ratings-stars">
                      <StarIcon className="start-b" />
                      <StarIcon className="start-b" />
                      <StarIcon className="start-b" />
                      <StarIcon className="start-b" />
                      <StarIcon className="start-g" />
                    </div>
                    <div className="ratings-numbers">140,52</div>
                  </div>
                </div> */}
              </div>

              <div className="product-des">
                <div className="uni-heading">Description</div>
                <p className="">{data.description}</p>
              </div>

              {/* table-cNoScroll */}
              <div style={{ position: "relative" }}>
                <div className="table-c ">
                  <table className="seller-price-table pdt">
                    <tr className="tablerow-head">
                      <th className="table-head">Seller</th>
                      <th className="table-head">Membership</th>
                      <th
                        onMouseEnter={() => setIsShown(true)}
                        onMouseLeave={() => setIsShown(false)}
                        className="table-head infoic"
                      >
                        Price <img src={Imginfo} />
                      </th>
                      <th className='table-head'>Action</th>
                      
                    </tr>

                    {data.products &&
                      data.products.map((item, index) => {
                        return (
                          <tr className="tablerow-data">
                            <td
                              className="table-data"
                              style={{ textAlign: "center" }}
                            >
                              <Link to={`/seller/${item.vendor.id}`}>
                                {item.vendor?.vendor.company}
                              </Link>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span className={item.vendor?.member.membership}>
                                {item.vendor?.member.membership}
                              </span>
                            </td>
                            <td
                              className="table-data"
                              style={{ textAlign: "center",textTransform: 'capitalize' }}
                            >
                              ₹{item.max_price} / {item.unit}
                            </td>

                            <td
                              className="table-data"
                              style={{ textAlign: "center" }}
                            >
                              <Link to={`/product/${item.id}/seller`}>
                                View
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </table>
                </div>

                {isShown && (
                  <div
                    onMouseEnter={() => setIsShown(true)}
                    onMouseLeave={() => setIsShown(false)}
                    className="infoic-box"
                  >
                    <h5>Information</h5>
                    <h4>Exfactory Cost</h4>
                    <p>
                      The actual price will vary and depends upon multiple
                      factors including the volume purchased and the market
                      price of the day. The actual price will be calculated on
                      the shipping page
                    </p>
                    <h4>Landed Cost</h4>
                    <p>
                      The actual Landed cost will vary and depends upon the
                      volume and the logistics partner selected. The actual
                      landed price will be calculated on the shipping page
                    </p>
                  </div>
                )}
              </div>

              <div className="price-desc">
                <div className="price-heading">Price</div>
                <div className="ite-price-container">
                  {/* <span className="price old-price">₹60</span> */}
                  <span className="price new-price">
                    ₹{data.average_price?.price || "-----"} /{" "}
                    {data.average_price?.unit}
                  </span>
                  {/* <span className="price-span">Per Piece</span> */}
                </div>
                {/* <div className="discount-percentage">(10% Off)</div> */}
                {/*<div className="price-btn-container df aic">
                  <button className="price-btn disabled">Buy Now</button>
                  <button
                    to={`/rfqstep1/${data.id}`}
                    className="price-btn disabled"
                    style={{ marginRight: "0px" }}
                  >
                    RFQ
                  </button>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <ProductDesc about={data.vendor} documents={data.documents || []} />
      </section>
      {related && (
        <section>
          <h2 className="h2-heading">Related Products</h2>
          <Slider data={related} />
        </section>
      )}
      <Footer />
    </>
  );
}

export default Product_Details;
