import Logo from "../../assets/rklogo.png";
import facebook from "../../assets/facebook.png";
import twitter from "../../assets/twitter.png";
import linkedin from "../../assets/linkedin.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
// import PopupSignup from "../dashboard/Login/PopupSignup";
import PopupNumber from "../dashboard/Login/PopupNumber";
import PopupOtp from "../dashboard/Login/PopupOtp";

function Footer() {
  const [isSignup, setSignup] = useState(0);

  function No(index) {
    setSignup(index);
  }

  return (
    <>
      <div className={isSignup === 3 ? "popup-c active-popup" : "popup-c"}>
        <div className="login-signup-overlay"></div>
        <div className="login-signup-box">
          <CloseIcon className="popup-i" onClick={() => No(0)} />
          <PopupNumber func1={() => No(4)} func2={() => No(1)} />
        </div>
      </div>
      <div className={isSignup === 4 ? "popup-c active-popup" : "popup-c"}>
        <div className="login-signup-overlay"></div>
        <div className="login-signup-box">
          <CloseIcon className="popup-i" onClick={() => No(0)} />
          <PopupOtp func1={() => No(2)} func2={() => No(1)} />
        </div>
      </div>
      <footer className="footer">
        <div className="upper-footer">
          <div className="logodiv">
            <a href="/">
              <img className="footer-logo" src={Logo} alt={Logo} />
              <span>Rasayan</span>Kart
            </a>
            <p>View, Buy, Sell</p>
            <p>
              RK offers you that much-needed working capital. On-time payment
              guarantee, and a market with untapped potential. Join us today to
              increase your profits and fast-track your business with RK.
            </p>

            <p>Chemical trading simplified.</p>
            <div className="social_icon">
              <a href="/" target="_blank">
                <img src={facebook} alt={facebook} />
              </a>
              <a href="/" target="_blank">
                <img src={twitter} alt={twitter} />
              </a>
              <a href="/" target="_blank">
                <img src={linkedin} alt={linkedin} />
              </a>
            </div>
          </div>
          <div className="ul-f">
            <h2>Explore</h2>
            <ul>
              <li className="footer-li">
                <Link to="/shop"> Buy</Link>
              </li>
              <li className="footer-li">
                <a href="http://seller.rasayankart.in/" target="_blank">
                  Sell
                </a>
              </li>
              {/* <li className="footer-li">Companies</li> */}
              {/* <li className="footer-li">Market</li> */}
              {/* <li className="footer-li">Chemicals</li> */}
              {/* <li className='footer-li'>Fourms</li> */}
            </ul>
          </div>
          <div className="ul-f">
            <h2>Know More</h2>
            <ul>
              <li className="footer-li">
                <Link to="/about-us">About us</Link>
              </li>
              <li className="footer-li">
                <Link to="/contact-us">Contact us</Link>
              </li>
              <li className="footer-li">
                <Link to="/faqs">FAQ's</Link>
              </li>
              <li className="footer-li">
                <Link to={"/register"}>Register</Link>
              </li>
            </ul>
          </div>
          <div className="ul-f">
            <h2>Policy</h2>
            <ul>
              {/* <li className='footer-li'>About Us</li> */}
              <li className="footer-li">
                <Link to="/tnc">Terms and Conditions</Link>
              </li>
              <li className="footer-li">
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li className="footer-li">
                <Link to="/return">Return Policy</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="bottom-footer">
          {/* <p>All &copy; rights are owned by </p> */}
          <p>Copyright {new Date().getFullYear()} Powered by Rasayan Kart. All Rights Reserved</p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
