import "./product.css";
import Verified from "../../../../assets/verified.png";
import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import ProductDetails from "../../Product_Details/ProductDetails";
import { baseUrl } from "../../../../utils/baseUrl";

function ProductRelatedCard({
  name,
  slug,
  img,
  price,
  description,
  company,
  average_price,
  redirect = false,
}) {
  return (
    <div className="product-card">
      <div className="verified-tag">
        <img src={Verified} alt="" />
        RK Trusted
      </div>
      <div className="product-img">
        <img
          src={img}
          alt="img"
          style={{ maxWidth: "100%" }}
          onError={(e) => {
            e.target.src = "/no-image.png";
          }}
        />
      </div>
      <div>
        <Link to={redirect || `/product/${slug}`} className="product-name text-capitalize">
          {name}
        </Link>
        <div className="product-loc">
          <div className="product-loc-child text-capitalize">{company || "Rasayankart"}</div>
          <div className="product-loc-child"></div>
        </div>
        <div className="product-price">
          <div className="product-price-child1">
            ₹{price || "---"}
            <div className="i-btn">
              i
              <div className="hover-box">
                <strong>Information</strong>
                <p>{description}</p>
              </div>
            </div>
          </div>
          <div className="product-loc-child"></div>
        </div>
      </div>
      <Link to={redirect || `/product/${slug}`} className="product-button">
        Product Details
      </Link>
    </div>
  );
}

export default ProductRelatedCard;
