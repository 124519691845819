import "./shop.css";
import Navbar from "../Components/Navbar/Navbar";
import Filter from "../Components/Filters/Filter";
import Navfilters from "../Components/Navfilters/Navfilters";
import { useEffect, useState } from "react";
import { SplFilter } from "../Components/SplFilter/SplFilter";
import Clear from "@mui/icons-material/Clear";
import Footer from "../../landing_page/Footer";
import { baseUrl } from "../../../utils/baseUrl";
import { toast } from "react-toastify";
import Product from "../Components/Product/Product";
import Pagination from "../../../component/Pagination";

function Shop() {
  const [Sidebar, setSidebarActive] = useState(false);

  const [data, setData] = useState([]);
  const [price, setPrice] = useState({ from_price: "", to_price: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [count, setCount] = useState({});

  const [categories, setcategories] = useState([]);
  const [cities, setcities] = useState([]);

  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSort, setSelectedSort] = useState("");

  const SidebarFunc = () => {
    Sidebar ? setSidebarActive(false) : setSidebarActive(true);
  };

  const fetchApi = async () => {
    const { from_price, to_price } = price;
    const response = await fetch(
      `${baseUrl}/catalog?page=${currentPage}&sort=${selectedSort}&category_ids=${selectedCategories}&cities=${selectedCity}&from_price=${from_price}&to_price=${to_price}`,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
    const result = await response.json();

    if (result.error) {
      toast.error(result.message);
      return;
    }
    setData(() => result.data.data);
    setLastPage(result.data.last_page);
    setCount(() => ({ from: result.data.from, to: result.data.to }));
  };

  const categoriesApi = async () => {
    const response = await fetch(`${baseUrl}/catalog/categories`);

    const result = await response.json();

    if (result.error) {
      toast.error(result.message);
      return;
    }
    setcategories(() => result.data);
  };

  const citiesApi = async () => {
    const response = await fetch(`${baseUrl}/catalog/cities`);

    const result = await response.json();

    if (result.error) {
      toast.error(result.message);
      return;
    }
    setcities(() => result.data);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const applyCategory = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(
        selectedCategories.filter((item) => item !== category)
      );
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const applyCity = (city) => {
    if (selectedCity.includes(city)) {
      setSelectedCity(selectedCity.filter((item) => item !== city));
    } else {
      setSelectedCity([...selectedCity, city]);
    }
  };

  const applySort = async (val) => {
    setSelectedSort(val);
  };

  useEffect(() => {
    citiesApi();
    categoriesApi();
  }, []);

  useEffect(() => {
    const body = document.querySelector("#root");

    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
    fetchApi();
  }, [currentPage, selectedCity, selectedCategories, selectedSort]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchApi();
      console.log('slide changes');
    }, 500);

    return () => clearTimeout(timeout);
  }, [price]);

  return (
    <>
      <section className="main-section">
        <Navbar />
        <div className="divider">
          <div
            className={
              Sidebar === true ? "left-part active-sidebar" : "left-part"
            }
          >
            <Clear className="clr-btn filter-close" onClick={SidebarFunc} />
            <Filter
              category={categories}
              cities={cities}
              applyCategory={applyCategory}
              applyCity={applyCity}
              setPrice={setPrice}
            />
            {/* <button onClick={SidebarFunc} className="callbtn2"><AddIcon className="callbtn2-i" /></button> */}
          </div>
          <div className="right-part">
            <Navfilters applySort={applySort} count={count} />
            <SplFilter func2={SidebarFunc} applySort={applySort} />

            <div className="shop-card-container">
              {data.map((item, index) => {
                return (
                  <Product
                    key={index}
                    img={item.url}
                    description={item.description}
                    name={item.name}
                    slug={item.slug}
                    price={item.price}
                    average_price={item.average_price}
                  />
                );
              })}
            </div>

            <Pagination
              handlePageChange={handlePageChange}
              lastPage={lastPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Shop;
