import Img from "../../assets/flow.png";

function Quality() {
  return (
    <section className="landing-sec-3">
      <div className="row-box">
        <div className="row-box-1">
          <span>Buy On Rasayan Kart</span>
          <h1>Reduce Cost.</h1>
          <p>
            Reduce costs and streamline your chemical procurement process with
            Rasayankart, your reliable platform for cost-effective sourcing and
            efficient transactions
          </p>
        </div>
        <div className="row-box-2">
          <div className="bluebox">
            <img
              className="i-right"
              style={{ height: "100%", width: "100%" }}
              src={Img}
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="row-box od">
        <div className="row-box-1">
          <span>Sell On Rasayan Kart</span>
          <h1>Grow Sales.</h1>
          <p>
            Unlock growth opportunities and expand your sales reach with Rasayan
            Kart, the ultimate destination for connecting with potential
            customers and driving business growth in the chemical industry.
          </p>
        </div>
        <div className="row-box-2">
          <div className="bluebox">
            <img
              className="i-left"
              style={{ height: "100%", width: "100%" }}
              src={Img}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Quality;
