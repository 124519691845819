import I1 from "../../assets/i1.png";
import I2 from "../../assets/i2.png";
import I3 from "../../assets/i3.png";

function WorkCard({ img, title, para }) {
  return (
    <div className="work-card">
      <div className="work-card-img df aic jcc">
        <img src={img} alt="" />
      </div>
      <div className="work-card-details">
        <h4>{title}</h4>
        <p>{para}</p>
      </div>
    </div>
  );
}

function Work() {
  return (
    <section className="landing-sec-4">
      <h1 className="h-t-h">How it works</h1>
      <p className="h-t-p">
        Unveil the magic of Rasayan Kart, revolutionizing chemical trading.
        Simplify your buying and selling journey and elevate your business
        effortlessly.
      </p>

      <div className="work-card-container">
        <WorkCard
          img={I1}
          title="Setup Selling Dashboard"
          para={
            "Supercharge your chemical sales! Create a sales dashboard, access endless opportunities to effortlessly showcase, promote and sell chemicals."
          }
        />
        <WorkCard
          img={I2}
          title="Invite Customers"
          para={
            "Amplify chemical sales, attract new customers, and foster partnerships. Unleash business growth by inviting exploration and collaboration on our platform."
          }
        />
        <WorkCard
          img={I3}
          title="Start Selling"
          para={
            "Seize selling opportunities! Enter the chemical trade world, connect with buyers, and empower business growth through our vast network."
          }
        />
      </div>
    </section>
  );
}

export default Work;
