import "./cart.css";
import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import AddIcon from "@mui/icons-material/Add";
import productImg from "../../../assets/product.png";
import { useState } from "react";
import useRazorpay from "react-razorpay";
import {
  BrowserRouter as Router,
  Link,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Footer from "../../landing_page/Footer";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { useEffect } from "react";

import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  InputLabel,
  Input,
  FormHelperText,
} from "@mui/material";
import { baseUrl } from "../../../utils/baseUrl";
import { toast } from "react-toastify";
import { toasterUpdate } from "../../../utils/toasterUpdate";

function Cart() {
  const [isActive, setisActive] = useState(false);
  const [isPopup, setisPopup] = useState(false);
  const [isPopup2, setisPopup2] = useState(false);

  const [pickup, setPickup] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState(false);
  const [summary, setSummary] = useState(false);
  const [addresses, setAddresses] = useState(false);
  const [billing_contact, setbilling_contact] = useState("");
  const [billing_name, setbilling_name] = useState("");
  const navigate = useNavigate();

  const [selectedAddress, setSelectedAddress] = useState({ id: "" });

  const [fromAddress, setFromAddress] = useState(null);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);

  const selectFromAddress = (selected) => {
    setFromAddress({
      deliver_from_address: selected.address,
      deliver_from_city: selected.city,
      deliver_from_state: selected.state,
      deliver_from_country: selected.country,
      deliver_from_zip: selected.zip,
    });
  };

  const selectShipAddress = (selected) => {
    setSelectedAddress({ id: selected.id });

    setShippingAddress({
      shipping_address: selected.address,
      shipping_city: selected.city,
      shipping_state: selected.state,
      shipping_country: selected.country,
      shipping_zip: selected.zip,
    });

    selectBillAddress(selected);
  };

  const selectBillAddress = (selected) => {
    setBillingAddress({
      billing_address: selected.address,
      billing_city: selected.city,
      billing_state: selected.state,
      billing_country: selected.country,
      billing_zip: selected.zip,
    });
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const id = searchParams.get("_id");
  const qty = searchParams.get("qty");

  const fetchApi = async () => {
    setLoading(true);
    const token = localStorage.getItem("token_web");
    const toastID = toast.loading("Loading...");
    const response = await fetch(
      `${baseUrl}/product/${id}/checkout?qty=${qty}&delivery_type=${
        pickup && "pickup"
      }`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toast.dismiss(toastID);
    setData(() => result.data.data);
    setSummary(() => result.data.summary);
    setAddresses(() => result.data.addresses || []);
    selectShipAddress(result.data.addresses[0] || {});
    setLoading(false);
  };

  // useEffect((useEffect) => {
  //     $('#delivery-id').hide();

  //     $('#onlineradio').click(function () {
  //         $('#delivery-id').show();
  //         $('#pickup-id').hide();
  //     })
  //     $('#transferradio').click(function () {
  //         $('#delivery-id').hide();
  //         $('#pickup-id').show();
  //     })
  // }, []);

  const checkout = async () => {
    const token = localStorage.getItem("token_web");

    if (!token) {
      toast.error("You're not logged in.");
      return;
    }

    if (pickup && !fromAddress) {
      toast.error("Please select pickup address");
      return;
    }

    if (!pickup && !shippingAddress) {
      toast.error("Please select shipping address");
      return;
    }

    if (!billing_name && !billing_contact) {
      toast.error("Billing name & phone number is required");
      return;
    }

    const final = JSON.stringify({
      ...billingAddress,
      ...shippingAddress,
      ...fromAddress,
      product_id: id,
      quantity: qty,
      delivery_type: pickup ? "pickup" : "drop",
      billing_contact,
      billing_name,
      unit: data.unit,
    });

    const cart = sessionStorage.setItem("cart", final);
    navigate(`/payment`);
  };

  const handleNumber = (e) => {
    let inputNumber = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    inputNumber = inputNumber.slice(0, 10);
    setbilling_contact(inputNumber);
  };

  useEffect(() => {
    fetchApi();

    if (!pickup) {
      setFromAddress(null);
    }

    return () => toast.dismiss();
  }, [pickup]);

  return (
    <>
      <section className="place-sec-1" style={{ backgroundColor: "#F9F9F9" }}>
        <NavbarAuth />
        <h1 className="po-h1">Order Summary</h1>
        <div className="place-order-container">
          <div className="place-order-left">
            <h3>Billing</h3>

            <small>Seller Information</small>
            <hr />

            <div class="seller-info">
              <div>
                <p className="seller_name">{data.vendor?.vendor.company}</p>
                <p>GST : {data.vendor?.vendor.gst}</p>
                <p style={{ width: "350px" }}>
                  Shipping Address :
                  {shippingAddress &&
                    `${shippingAddress.shipping_address},
                  ${shippingAddress.shipping_city},
                  ${shippingAddress.shipping_state},
                  ${shippingAddress.shipping_country},
                  ${shippingAddress.shipping_zip}`}
                </p>
              </div>
              <div>
                <span className={data.vendor?.member.membership}>
                  {data.vendor?.member.membership}
                </span>
              </div>
            </div>

            <div className="delivery_type_div">
              <small>Delivery Type</small>
              <hr />

              <div style={{ marginTop: "50px" }}>
                <FormControl className="d_flex">
                  <RadioGroup
                    className="d_flex flex_row deliver_pickup-btns"
                    aria-labelledby="delivery_type"
                    name="delivery_type"
                  >
                    <button className="checkout-btn">
                      <FormControlLabel
                        value="pickup"
                        checked={pickup}
                        onChange={() => setPickup(true)}
                        control={<Radio />}
                        label="Pickup Address"
                      />
                    </button>
                    <button className="checkout-btn">
                      <FormControlLabel
                        value="delivery"
                        checked={!pickup}
                        onChange={() => setPickup(false)}
                        control={<Radio />}
                        label="Delivery Address"
                      />
                    </button>
                  </RadioGroup>
                </FormControl>

                {pickup && (
                  <FormControl className="d_flex">
                    <RadioGroup
                      className="d_flex flex_row"
                      name="pickup_address"
                    >
                      <div className="addresses">
                        {data.vendor &&
                          data.vendor.address &&
                          data.vendor.address.map((address, index) => {
                            return (
                              <div className="address_box" key={index}>
                                <FormControlLabel
                                  value={address.id}
                                  onChange={() => selectFromAddress(address)}
                                  control={<Radio />}
                                  label={`${address.address} ${address.state} ${address.city} ${address.country} ${address.zip}`}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </RadioGroup>
                  </FormControl>
                )}

                {!pickup && (
                  <FormControl className="d_flex">
                    <RadioGroup className="d_flex flex_row" name="drop_address">
                      <div className="addresses">
                        {addresses &&
                          addresses.map((address, index) => {
                            return (
                              <div className="address_box" key={index}>
                                <FormControlLabel
                                  value={address.id}
                                  checked={selectedAddress.id == address.id}
                                  onChange={() => selectShipAddress(address)}
                                  control={<Radio />}
                                  label={`${address.address} ${address.state} ${address.city} ${address.country} ${address.zip}`}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </RadioGroup>
                  </FormControl>
                )}
              </div>
            </div>

            <div className="delivery_type_div">
              <small>Contact Info</small>
              <hr />

              <div
                style={{
                  marginTop: "50px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <FormControl>
                  <InputLabel htmlFor="my-input">Your Name *</InputLabel>
                  <Input
                    id="my-input"
                    type="text"
                    required
                    onInput={(e) => setbilling_name(e.target.value)}
                    aria-describedby="my-helper-text"
                  />
                </FormControl>
                <FormControl>
                  <InputLabel htmlFor="my-input">Phone Number *</InputLabel>
                  <Input
                    id="my-input"
                    type="number"
                    value={billing_contact}
                    onInput={handleNumber}
                    required
                    aria-describedby="my-helper-text"
                  />
                </FormControl>
              </div>
            </div>
          </div>
          <div className="place-order-right">
            <div className="product_info82">
              <div
                class="big-bold-heading tc"
                style={{ padding: "15px", textAlign: "left" }}
              >
                Product Info
              </div>

              {data && (
                <div className="cartCardOuter">
                  <div className="cardProImg">
                    <img
                      src={data.url}
                      alt={data.name}
                      onError={(e) => {
                        e.target.src = "/no-image.png";
                      }}
                    />
                  </div>
                  <div className="cardProInfo">
                    <p>{data.name}</p>
                    <div className="cartCardItem">
                      <p>Seller : {data.vendor?.vendor.company}</p>
                    </div>
                    <div className="cartCardItem">
                      <p>City : {data.vendor?.vendor.city}</p>
                    </div>
                    <div className="cartCardItem">
                      <p>Qty : {summary && summary.qty}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="payment-summary-box mb-3">
              <div className="blue-box">
                <div>
                  {pickup && fromAddress && (
                    <div className="bold-heading bold-heading88">
                      <b>From :</b>
                      <p>
                        {fromAddress.deliver_from_address},
                        {fromAddress.deliver_from_city},
                        {fromAddress.deliver_from_state},
                        {fromAddress.deliver_from_country},
                        {fromAddress.deliver_from_zip}
                      </p>
                    </div>
                  )}
                </div>
                <div>
                  <div className="bold-heading bold-heading88">
                    <b>To :</b>
                    <p>
                      {shippingAddress &&
                        `${shippingAddress.shipping_address},
                  ${shippingAddress.shipping_city},
                  ${shippingAddress.shipping_state},
                  ${shippingAddress.shipping_country},
                  ${shippingAddress.shipping_zip}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="payment-summary-box">
              <div
                className="big-bold-heading tc"
                style={{ marginBottom: "50px", textAlign: "left" }}
              >
                Payment Summary
              </div>
              <div className="blue-box">
                <div className="df jcb cart-help-p">
                  <div className="bold-heading bold-heading88">
                    Product Price (INR)
                  </div>
                  <p className="">{summary && summary.subtotal}</p>
                </div>
                <div className="df jcb cart-help-p">
                  <div className="bold-heading bold-heading88">
                    Product GST (INR)
                  </div>
                  <p className="">{summary && summary.gst}</p>
                </div>
                <div className="df jcb cart-help-p">
                  <div className="bold-heading bold-heading88">
                    Logistics Price (INR)
                  </div>
                  <p className="">{summary && summary.logistic}</p>
                </div>
                {/* <div className="df jcb cart-help-p">
                  <div className="bold-heading bold-heading88">
                    Logistics GST (INR)
                  </div>
                  <p className="">1,252.00</p>
                        </div>*/}
              </div>
            </div>

            <button className="backbtn">Back</button>
            <button disabled={loading} onClick={checkout} className="contbtn">
              Continue
            </button>
          </div>
        </div>
      </section>

      <div className={isPopup2 ? "popupbox active-popup" : "popupbox"}>
        <div className="popup-overlay"></div>
        <div className="popup-content">
          <div style={{ minHeight: "451px", padding: "30px" }}>
            <CloseIcon
              className="popup-i"
              onClick={() => setisPopup2(!isPopup2)}
            />
            <h1>Edit Address</h1>

            <div className="edit-address-popup-div">
              <label htmlFor="address_line_1">Adress Line 1</label>
              <input type="text" id="address_line_1" name="address_line_1" />
            </div>
            <div className="edit-address-popup-div">
              <label htmlFor="address_line_2">Adress Line 2</label>
              <input type="text" id="address_line_2" name="address_line_2" />
            </div>

            <div className="df inner-edit-add-box">
              <div className="edit-address-popup-div">
                <label htmlFor="city">City</label>
                <input type="text" id="city" name="city" />
              </div>
              <div className="edit-address-popup-div">
                <label htmlFor="state">State</label>
                <input type="text" id="state" name="state" />
              </div>
            </div>

            <div className="df inner-edit-add-box">
              <div className="edit-address-popup-div">
                <label htmlFor="pincode">Pincode</label>
                <input type="text" id="pincode" name="pincode" />
              </div>
              <div className="edit-address-popup-div">
                <label htmlFor="number">Phone Number</label>
                <input type="number" id="number" name="number" />
              </div>
            </div>
          </div>

          <Link
            to="/cart"
            className="contbtn"
            style={{ width: "90%", margin: "0px auto", marginTop: "16px" }}
          >
            Continue
          </Link>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Cart;
