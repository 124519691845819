import React from "react";
import "./landing.css";
import "./sidebar.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { NavbarAuth } from "../dashboard/Components/Navbar/Navbar";

const Return = () => {
  const token = localStorage.getItem("token_web");

  return (
    <>
      <section className="landing-sec-2New">
      {token ? <NavbarAuth /> : <Navbar />}


        <div className="contactUsOuter">
          <div className="contactUsInner">
            <div className="ConatctleftSec">
              <h2>RETURN POLICY</h2>
            </div>
          </div>
        </div>
      </section>

      <div
        className="ContactContentSection aboutus"
        style={{ position: "relative" }}
      >
        <div className="ContactContentSectionRight aboutus">
          <div className="aboutUsSectionOter">
            <ol>
              <li>
                <p class="fw-bold">Overview</p>
                <p>
                  Rasayankart operates as a B2B online marketplace connecting
                  buyers and sellers of API/ chemical products.
                </p>
                <p>
                  This Return Policy outlines our policy regarding the return of
                  chemical products purchased through our platform. Please read
                  this policy carefully before using our Service.
                </p>
              </li>
              <li>
                <p class="fw-bold">Return Eligibility</p>
                <p>
                  Returns are subject to the terms and conditions agreed upon
                  between the buyer and the seller, as specified in the
                  individual sales agreements or contracts. We act as an
                  intermediary and do not hold responsibility for the terms of
                  these agreements.
                </p>
                <p>
                  Eligibility for returns may vary depending on the type of
                  chemical product, its condition upon delivery, and the terms
                  set by the seller in their product listings.
                </p>
              </li>
              <li>
                <p class="fw-bold">Return Process</p>
                <p>
                  Buyers wishing to return chemical products should follow the
                  return process specified by the seller in the product listing
                  or the sales agreement.
                </p>
                <p>
                  Sellers are responsible for clearly communicating their return
                  process to buyers and addressing return requests promptly.
                </p>
                <p>
                  Rasayankart does not manage the return process, including
                  return shipping, return shipping costs, or disputes related to
                  returns. These matters are the sole responsibility of the
                  buyer and seller.
                </p>
              </li>
              <li>
                <p class="fw-bold">Disputes and Resolution</p>
                <p>
                  Any disputes related to returns should be addressed directly
                  between the buyer and the seller, as outlined in terms of sale
                  agreement or PO/Invoice.
                </p>
              </li>
              <li>
                <p class="fw-bold">Refunds</p>
                <p>
                  Refunds, if applicable, are subject to the terms and
                  conditions agreed upon between the buyer and the seller, as
                  specified in the individual sales agreements or contracts.
                </p>
                <p>
                  Rasayankart does not process refunds directly. Refunds are the
                  responsibility of the seller, and the process should be
                  outlined in the sales agreement or contract.
                </p>
              </li>
              <li>
                <p class="fw-bold">Hazardous Materials</p>
                <p>
                  For chemical products classified as hazardous materials,
                  returns may be subject to additional regulations and
                  restrictions, including compliance with hazardous materials
                  shipping regulations.
                </p>
                <p>
                  Buyers and sellers are responsible for adhering to all
                  relevant safety and regulatory requirements when handling
                  hazardous materials.
                </p>
              </li>
              <li>
                <p class="fw-bold">Contact Information</p>
                <p>
                  For any questions or concerns related to returns, please
                  contact the seller support directly using the contact
                  information provided in the sale agreement or product listing.
                  If you do not have contact details, you can reach out to our
                  Support Executives.
                </p>
              </li>
              <li>
                <p class="fw-bold">Changes to this Return Policy</p>
                <p>
                  We reserve the right to update or change this Return Policy at
                  any time. Any changes will be posted on this page.
                </p>
              </li>
              <li>
                <p class="fw-bold">Contact Us</p>
                <p>
                  If you have any questions about this Return Policy, please
                  contact us at info@rasayankart.in.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Return;
