import "./pd.css";
import { useState, useRef, useEffect } from "react";
import ReactImageMagnify from "react-image-magnify";

function Pd({ base_image, additional }) {
  const images = [{ url: base_image }, ...additional];

  const [img, setImg] = useState(base_image);

  const hoverHandler = (image, i) => {
    setImg(image);
    refs.current[i].classList.add("active");
    for (var j = 0; j < images.length; j++) {
      if (i !== j) {
        refs.current[j].classList.remove("active");
      }
    }
  };
  const refs = useRef([]);
  refs.current = [];
  const addRefs = (el) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  useEffect(() => {
    setImg(base_image);
  }, []);

  return (
    <div className="container-pd">
      <div className="left">
        <div className="left_1">
          {images.map((item, i) => (
            <div
              className={i === 0 ? "img_wrap active" : "img_wrap"}
              key={i}
              onMouseOver={() => hoverHandler(item.url, i)}
              ref={addRefs}
            >
              <img src={item.url} alt="" onError={(e) => {e.target.src = '/no-image.png'}} />
            </div>
          ))}
        </div>
        <div className="left_2">
          <img src={img || base_image} alt="" onError={(e) => {e.target.src = '/no-image.png'}} />
        </div>
      </div>
      <div className="right"></div>
    </div>
  );
}

export default Pd;
