import React, { useState } from "react";
import style from "./pagination.module.css";

function Pagination({ handlePageChange, currentPage, lastPage }) {
  return (
    <div className={style.pagination_row}>
      <button
        className={`${style.button} ${style.bgGrey}`}
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <img
          src="/images/chevron-left.png"
          alt="left icon"
          className={style.icon}
        />
      </button>

      {Array.from({ length: lastPage }, (_, index) => (
        <button
          className={`${style.button} ${
            currentPage == index + 1 ? style.active : ""
          }`}
          disabled={currentPage === index + 1}
          key={index}
          onClick={() => handlePageChange(index + 1)}
        >
          {index + 1}
        </button>
      ))}

      <button
        className={`${style.button} ${style.bgGrey}`}
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === lastPage}
      >
        <img
          src="/images/chevron-right.png"
          alt="left icon"
          className={style.icon}
        />
      </button>
    </div>
  );
}

export default Pagination;
