import './pdf.css' ;
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImportImg from '../../../../assets/Import.png';
import treeImg from '../../../../assets/tree3.png';
import $ from 'jquery';
import "jquery-ui-dist/jquery-ui";
import {useEffect} from "react";



function Pdf(props) {
    useEffect ((useEffect)=>{
// $('.PDFActionIcon').click(function(){
//     $('.documentActions').show();
// })
    },[])
    
    return ( 
        <div className="pdf-card">
                <img className='PDFActionIcon' src={treeImg} />
                <ul className='documentActions'>
                    <li>Edit Document</li>
                    <li>Delete Document</li>
                </ul>
            <div className="pdf-header">
                <PictureAsPdfIcon fontSize="large"/>
            </div>
            <div className="pdf-details">
                <div>
                    <div className="pdf-name">{props.name}</div>
                    <div className="pdf-size">{props.size}</div>
                </div>
                <div>
                    <img src={ImportImg} />
                </div>
                {/* <button>Download</button> */}
            </div>
        </div>
     );
}

export default Pdf;