import React from "react";
import "./landing.css";
import "./sidebar.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { NavbarAuth } from "../dashboard/Components/Navbar/Navbar";

const TnC = () => {
  const token = localStorage.getItem("token_web");

  return (
    <>
      <section className="landing-sec-2New">
        {token ? <NavbarAuth /> : <Navbar />}

        <div className="contactUsOuter">
          <div className="contactUsInner">
            <div className="ConatctleftSec">
              <h2>TERMS AND CONDITIONS OF USE</h2>
              <p>
                These Terms and Conditions of Use ("Agreement") constitute a
                legally binding agreement between you, a user of the website,
                application, or services provided by Rasayankart. By accessing
                or using the Rasayankart platform for API/Chemicals, you agree
                to be bound by this Agreement. Please read this Agreement
                carefully before accessing or using our services.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div
        className="ContactContentSection aboutus"
        style={{ position: "relative" }}
      >
        <div className="ContactContentSectionRight aboutus">
          <div className="aboutUsSectionOter">
            <ol>
              <li>
                <p class="fw-bold">WEBSITE - MERELY A VENUE/PLATFORM</p>
                <p>
                  RASAYANKART provides a platform that connects buyers and
                  suppliers of API/Chemicals. We act as a facilitator and do not
                  take part in the transactions between users. We are not
                  responsible for the quality, safety, or legality of the
                  products or services provided by users.
                </p>
              </li>
              <li>
                <p class="fw-bold">SERVICES PROVIDED BY RASAYANKART</p>
                <p>
                  RASAYANKART offers a platform for users to list, buy, and sell
                  API/Chemicals. We may provide additional services and
                  features, but the core function is to facilitate business
                  transactions.
                </p>
              </li>
              <li>
                <p class="fw-bold">USER(S) ELIGIBILITY</p>
                <p>
                  You must be legally capable and eligible to enter contracts
                  under applicable laws to use our services. By using our
                  platform, you represent and warrant that you meet these
                  criteria.
                </p>
              </li>
              <li>
                <p class="fw-bold">USER(S) AGREEMENT</p>
                <p>
                  By using RASAYANKART, you agree to comply with this Agreement
                  and all applicable laws and regulations. If you do not agree
                  with any part of this Agreement, you should not use our
                  services.
                </p>
              </li>
              <li>
                <p class="fw-bold">AMENDMENT TO THE AGREEMENT</p>
                <p>
                  We may modify this Agreement at any time. Any changes will be
                  posted on our platform. Continued use of our services after
                  such modifications constitutes your acceptance of the revised
                  Agreement.
                </p>
              </li>
              <li>
                <p class="fw-bold">INTELLECTUAL PROPERTY RIGHTS</p>
                <p>
                  All content and intellectual property on our platform,
                  including but not limited to text, graphics, logos, images,
                  and software, are owned by RASAYANKART. You may not use,
                  reproduce, or distribute our content without our prior written
                  consent.
                </p>
              </li>
              <li>
                <p class="fw-bold">LINKS TO THIRD-PARTY SITES/CONTENT</p>
                <p>
                  Our platform may contain links to third-party websites or
                  content. We do not endorse or control these sites or content
                  and are not responsible for their accuracy, legality, or
                  reliability.
                </p>
              </li>
              <li>
                <p class="fw-bold">TERMINATION</p>
                <p>
                  We reserve the right to terminate or suspend your account and
                  access to our platform for any reason, including violations of
                  this Agreement.
                </p>
              </li>
              <li>
                <p class="fw-bold">REGISTERED USER(S)</p>
                <p>
                  Registered users may have access to additional features. You
                  are responsible for maintaining the confidentiality of your
                  account information and for all activities that occur under
                  your account.
                </p>
              </li>
              <li>
                <p class="fw-bold">DATA PROTECTION/PERSONAL INFORMATION</p>
                <p>
                  Your use of our platform is subject to our Privacy Policy,
                  which governs the collection, use, and disclosure of your
                  personal information.
                </p>
              </li>
              <li>
                <p class="fw-bold">POSTING YOUR CONTENTS ON RASAYANKART</p>
                <p>
                  By posting content on RASAYANKART, you grant us a
                  non-exclusive, worldwide, royalty-free, irrevocable, and
                  sub-licensable license to use, reproduce, modify, adapt,
                  publish, translate, create derivative works from, distribute,
                  and display such content.
                </p>
              </li>
              <li>
                <p class="fw-bold">INTERACTIONS BETWEEN USER(S)</p>
                <p>
                  All interactions between users, including transactions and
                  communications, are solely between the parties involved.
                  RASAYANKART is not responsible for any disputes, injuries, or
                  losses arising from these interactions.
                </p>
              </li>
              <li>
                <p class="fw-bold">LIMITATIONS OF LIABILITY/DISCLAIMER</p>
                <p>
                  We do not guarantee the accuracy, completeness, or reliability
                  of any content on our platform. We are not liable for any
                  direct, indirect, incidental, special, or consequential
                  damages resulting from your use of our services.
                </p>
              </li>
              <li>
                <p class="fw-bold">NOTICES</p>
                <p>
                  All notices under this Agreement may be sent to you at the
                  email address provided during registration. You agree that any
                  notices or communications sent to you electronically satisfy
                  any legal requirements.
                </p>
              </li>
              <li>
                <p class="fw-bold">GOVERNING LAW AND DISPUTE RESOLUTION</p>
                <p>
                  This Agreement is governed by the laws of India. Any disputes
                  arising from or relating to this Agreement shall be resolved
                  through arbitration in accordance with the guidelines of the
                  Indian Law, and the arbitration shall take place in Ahmadabad.
                </p>
              </li>
              <li>
                <p class="fw-bold">GENERAL/MISCELLANEOUS</p>
                <p>
                  If any part of this Agreement is found to be invalid or
                  unenforceable, the remaining provisions will remain in effect.
                  This Agreement constitutes the entire agreement between you
                  and RASAYANKART regarding the use of our platform. Our failure
                  to enforce any part of this Agreement does not waive our right
                  to enforce it later.
                </p>
              </li>
            </ol>
          </div>
          {/* end of aboutUsSectionOter */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TnC;
