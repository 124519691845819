import "./landing.css";
import "./sidebar.css";
import Navbar from "../dashboard/Components/Navbar/Navbar";
import Brands from "./Brands";
import Quality from "./Quality";
import Work from "./Work";
import { Reviews } from "./Reviews";
import { Contact, ContactForm } from "./Contact";
import Subscribe from "./Subscribe";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import PopupNumber from "../dashboard/Login/PopupNumber";
import PopupOtp from "../dashboard/Login/PopupOtp";
// Landing Images

import Limg1 from "../../assets/indeximg.png";
import Ellipse1 from "../../assets/Ellipse8.png";
import Ellipse2 from "../../assets/Ellipse9.png";
import PopupLogin from "../dashboard/Login/PopupLogin";
import CloseIcon from "@mui/icons-material/Close";
import PopupSignup from "../dashboard/Login/PopupSignup";

import { useState } from "react";

function Landing() {
  const navigate = useNavigate();
  const [Val, setVal] = useState(false);
  const [isSignup, setSignup] = useState(0);
  function No(index) {
    navigate("/register");
  }

  return (
    <>
      {/* NAVBAR + SECTION-1 */}
      <section className="landing-sec-1">
        <Navbar />

        <div className="sec1-div">
          <h1>
            Reduce Cost <br /> Increase Profits.
          </h1>
          <p>
            Join the Rasayankart Trade Network to discover <br /> a world of
            opportunities to buy or sell chemicals online.
          </p>
          <button onClick={() => No(3)}>Join Our Network</button>
        </div>

        <div className="LandingImg">
          <img className="li-1" src={Ellipse2} alt=" " />
          <img className="li-2" src={Ellipse1} alt=" " />
          <img className="li-3" src={Limg1} alt=" " />
        </div>

        {/* <div className={Val === true ? "popup-c active-popup" : "popup-c"}>
                    <div className="login-signup-overlay"></div>
                    <div style={{ height: '478px' }}  className="login-signup-box">
                        <div className="sub-popup contact-popup">
                            <CloseIcon className='popup-i' onClick={() => setVal(false)} />
                            <ContactForm w='90%' />

                        </div>
                    </div>
                </div> */}
        <div className={isSignup === 3 ? "popup-c active-popup" : "popup-c"}>
          <div className="login-signup-overlay"></div>
          <div className="login-signup-box">
            <CloseIcon className="popup-i" onClick={() => No(0)} />
            <PopupNumber func1={() => No(4)} func2={() => No(1)} />
          </div>
        </div>
        <div className={isSignup === 4 ? "popup-c active-popup" : "popup-c"}>
          <div className="login-signup-overlay"></div>
          <div className="login-signup-box">
            <CloseIcon className="popup-i" onClick={() => No(0)} />
            <PopupOtp func1={() => No(2)} func2={() => No(1)} />
          </div>
        </div>
      </section>

      {/* <Brands /> */}

      <Quality />

      <Work />

      {/* <Reviews /> */}

      <Contact />

      <Subscribe />

      <Footer />
    </>
  );
}

export default Landing;
