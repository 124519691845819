import { useState } from "react";
import { baseUrl } from "../../utils/baseUrl";
import { toast } from "react-toastify";
import { toasterUpdate } from "../../utils/toasterUpdate";
import { useRef } from "react";

function Contact() {
  return (
    <section id="landing-sec-6">
      <div className="flex-row">
        <div className="flex-50">
          <h1>
            Buy. Sell. <br /> Market on Rasayan Kart
          </h1>
          <p>
            Attract and interact with your company profile. Transact on your
            buying and selling dashboard.
          </p>
        </div>
        <div className="flex-50">
          <ContactForm />
        </div>
      </div>
    </section>
  );
}
function ContactForm() {
  const [contact, setContact] = useState({
    name: "",
    email: "",
    company: "",
    description: "",
  });

  const formRef = useRef();

  const submitApi = async (e) => {
    e.preventDefault();

    const toastID = toast.loading("Sending...");
    const response = await fetch(`${baseUrl}/contact`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...contact }),
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    formRef.current.reset();
    toasterUpdate(toastID, result.message, "success");
  };

  return (
    <form onSubmit={submitApi} className="reach-form mx-auto" ref={formRef}>
      <div className="form-heading">For inquiries, reach out to us!</div>
      <input
        type="text"
        required
        onInput={(e) => setContact({ ...contact, name: e.target.value })}
        placeholder="Name*"
        name="Name"
      />
      <input
        type="email"
        required
        onInput={(e) => setContact({ ...contact, email: e.target.value })}
        placeholder="Email*"
        name="email"
      />
      <input
        type="text"
        required
        onInput={(e) => setContact({ ...contact, company: e.target.value })}
        placeholder="Company*"
        name="company"
      />
      <textarea
        name="description"
        id=""
        required
        onInput={(e) => setContact({ ...contact, description: e.target.value })}
        placeholder="Description*"
      ></textarea>
      <button type="submit">Send Message</button>
    </form>
  );
}

export { Contact, ContactForm };
