import './cart.css';
import Navbar from '../Components/Navbar/Navbar';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import productImg from '../../../assets/product.png';

import { useState } from 'react';
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";
import Footer from '../../landing_page/Footer';

function Cart2() {
    const [isActive, setisActive] = useState(false);
    const [isPopup2, setisPopup2] = useState(false);
    return (
        <>
            <section className="place-sec-1">
                <Navbar />
                <h1 className='po-h1'>Order Summary</h1>
                <div className="place-order-container">

                    <div className="place-order-left">
                    <div className='bgGray' style={{background:"#f4f4f4"}}>
                        <div className="pol-header" >
                            <div className="pol-main-header" onClick={() => setisActive(!isActive)}>
                                <div className="big-bold-heading">Payment Summary</div>
                                <div className=""><AddIcon /></div>
                            </div>
                            <div className={isActive ? "pol-header-sibling fold" : "pol-header-sibling"}>
                                <div className="pol-header-sibling-child">
                                    <div className="bold-heading">
                                        Billing Details
                                    </div>
                                    <p>
                                        Mindtree, Banglore, Banglore Karnataka 560059
                                    </p>
                                    <span className="link" onClick={() => setisPopup2(!isPopup2)}  >Edit</span>
                                </div>
                                <div className="pol-header-sibling-child">
                                    <div className="bold-heading">GSTIN</div>
                                    <input type="text" />
                                </div>
                            </div>
                        </div>
                        <div className="pol-bottom w100">
                            <div className="df aic cart-p">
                                <div className="big-bold-heading">Seller</div>
                                <div className="blue-msg">Seller Offline, Pune</div>
                            </div>
                            <div className="df cart-p jcb cart-help-p emer-cart-op">
                                <div className="df fdc emer-cart-box">
                                    <div className="bold-heading">Product</div>
                                    <p>Camlin Engineers Blue Paste 20 ml</p>
                                </div>
                                <div className="df fdc emer-cart-box">
                                    <div className="bold-heading">Quantity</div>
                                    <p>1 Pack</p>
                                </div>
                                <div className="df fdc emer-cart-box">
                                    <div className="bold-heading">Amount</div>
                                    <p>10,499.00</p>
                                </div>
                            </div>
                            <div className="cart-p cart-no">
                                <strong>SKU :</strong> 250A55
                            </div>
                            <div className="pol-header-sibling">
                                <div className="pol-header-sibling-child">
                                    <div className="bold-heading">
                                        Shipping Details
                                    </div>
                                    <p>
                                        Mindtree, Banglore, Banglore Karnataka 560059
                                    </p>
                                    <span className="link" onClick={() => setisPopup2(!isPopup2)} >Edit</span>
                                </div>
                                <div className="pol-header-sibling-child">
                                    <div className="bold-heading">Expected Delivery 2-4 Days</div>

                                </div>
                            </div>
                            <div className="df fdc cart-p">
                                <div className="cart-inpu-box">
                                    <div className="bold-heading" style={{marginTop:"20px"}}>Contact Name</div>
                                    <input type="text" />
                                </div>
                                <div className="cart-inpu-box">
                                    <div className="bold-heading">Mobile Number</div>
                                    <input type="text" />
                                </div>
                            </div>

                        </div>
                        </div>
                        <div className="cart-milestone-box w-100">
                            <h1>Payment Milestone</h1>
                            <div className="pc-cart-r">
                                <span>Advance Payment</span>
                                <span>20,000.00</span>
                            </div>
                            <div className="input-heading-container">
                                <div className="input-heading">Delivery Date</div>
                                <div className="input-heading">Percentage</div>
                                <div className="input-heading">Payment</div>
                            </div>
                            <div className="input-rows">
                                <div className="ir1-1">30/10/2022</div>
                                <div className="ir1-1">20%</div>
                                <div className="ir1-1">5,00,000</div>
                            </div>
                            <div className="input-rows">
                                <div className="ir1-1">30/11/2022</div>
                                <div className="ir1-1">50%</div>
                                <div className="ir1-1">5,00,000</div>
                            </div>
                            <div className="input-rows">
                                <div className="ir1-1">30/12/2022</div>
                                <div className="ir1-1">30%</div>
                                <div className="ir1-1">5,00,000</div>
                            </div>
                        </div>
                    </div>
                    <div className="place-order-right">
                    <div className='product_info82'>
                            <div class="big-bold-heading tc" style={{padding:"15px",textAlign:"left"}}>Product Info</div>
                            <div className='cartCardOuter'>
                            <div className='cardProImg'>
                                <img src={productImg} />
                            </div>
                            <div className='cardProInfo'>
                                <p>Camlin Engineers Blue Paste 20 ml</p>
                                <div className='cartCardItem'>
                                    <p>Burhani suppliers</p>
                                    <p>Chennai</p>
                                </div>
                                <div className='cartCardItem'>
                                    <p>Qty : 50</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="payment-summary-box">
                            <div className="big-bold-heading tc" style={{textAlign:"left"}}>
                                Payment Summary
                            </div>
                            <div className="blue-box">
                                <div className="df jcb cart-help-p">
                                    <div className="bold-heading">Product Price (INR)</div>
                                    <p className="">10,000.00</p>
                                </div>
                                <div className="df jcb cart-help-p">
                                    <div className="bold-heading">Product GST (INR)</div>
                                    <p className="">1,000.00</p>
                                </div>
                                <div className="df jcb cart-help-p">
                                    <div className="bold-heading">Logistics  Price (INR)</div>
                                    <p className="">9,052.00</p>
                                </div>
                                <div className="df jcb cart-help-p">
                                    <div className="bold-heading">Logistics  GST (INR)</div>
                                    <p className="">1,252.00</p>
                                </div>
                            </div>
                        </div>

                        <Link to='/cart' className='backbtn'>Back</Link>
                        <Link to='/checkout' className='contbtn'>Continue</Link>
                    </div>
                </div>
            </section>
            <div className={isPopup2 ? "popupbox active-popup" : "popupbox"}>
                <div className="popup-overlay"></div>
                <div className="popup-content">
                    <div style={{ minHeight: "451px" , padding:"30px" }}>
                        <CloseIcon className='popup-i' onClick={() => setisPopup2(!isPopup2)} />
                        <h1>Edit Address</h1>

                        <div className="edit-address-popup-div">
                            <label htmlFor="address_line_1">Adress Line 1</label>
                            <input type="text" id='address_line_1' name='address_line_1' />
                        </div>
                        <div className="edit-address-popup-div">
                            <label htmlFor="address_line_2">Adress Line 2</label>
                            <input type="text" id='address_line_2' name='address_line_2' />
                        </div>

                        <div className="df inner-edit-add-box">
                            <div className="edit-address-popup-div">
                                <label htmlFor="city">City</label>
                                <input type="text" id='city' name='city' />
                            </div>
                            <div className="edit-address-popup-div">
                                <label htmlFor="state">State</label>
                                <input type="text" id='state' name='state' />
                            </div>
                        </div>

                        <div className="df inner-edit-add-box">
                            <div className="edit-address-popup-div">
                                <label htmlFor="pincode">Pincode</label>
                                <input type="text" id='pincode' name='pincode' />
                            </div>
                            <div className="edit-address-popup-div">
                                <label htmlFor="number">Phone Number</label>
                                <input type="number" id='number' name='number' />
                            </div>
                        </div>


                    </div>

                    <Link to='/cart' className="contbtn"  style={{width:"90%", margin:"0px auto", marginTop:"16px"}} >Continue</Link>
                </div>
            </div>
<Footer />

        </>
    );
}

export default Cart2;