import "./navbar.css";
import "./sidebar.css";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import NavbarLogin from "../../../landing_page/Navbar";

import { useEffect, useContext } from "react";
import Vs from "../../../../assets/vs.png";
import Rklogo from "../../../../assets/rklogo.png";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Backdrop from "@mui/material/Backdrop";
import Bag from "../../../../assets/Bag_fill.png";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from "@mui/material/Switch";

import StoreIcon from "@mui/icons-material/Store";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import InfoIcon from "@mui/icons-material/Info";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
// import EmailIcon from '@mui/icons-material/Email';
import LogoutIcon from "@mui/icons-material/Logout";
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {
  Email,
  InsertDriveFile,
  TextSnippet,
  Wallet,
} from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import { AuthContext } from "../../../../context/AuthContext";
import { baseUrl } from "../../../../utils/baseUrl";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
function Button(props) {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <Link
      to={props.to}
      className={
        props.val === true ? "sidebar-spl-btn active-btn" : "sidebar-spl-btn"
      }
    >
      {props.logo}
      {props.title}
    </Link>
  );
}

// searchbar component
const SearchBar = () => {
  const token = localStorage.getItem("token_web");

  const [list, setList] = useState([]);

  const searchApi = async (e) => {
    try {
      const response = await fetch(
        `${baseUrl}/product/search/${e.target.value}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const result = await response.json();

      if (result.error) {
        setList([]);
        return;
      }

      setList(result.data);
    } catch (error) {
      setList([]);
    }
  };

  return (
    <div className="nav-searchbox">
      <input
        placeholder="Search Product By Name Or CAS Number"
        onChange={searchApi}
        type="text"
      />
      <button>
        <SearchIcon />
      </button>

      {list.length > 0 && (
        <ul className="searchResultContainer">
          {list.map((el, index) => {
            return (
              <li style={{listStyle:'none', marginBottom:'5px'}}>
                <Link to={`/product/${el.id}/seller`} style={{display:'flex',gap:'20px',alignItems:'center'}}>
                  <img src={el.url} alt="Base Image" style={{width:"60px",objectFit:'contain'}} />
                  <p>{el.name}</p>
                  <p>₹{el.max_price} / {el.unit}</p>
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
// searchbar component

const NavbarAuth = () => {
  const [Sidebar, setSidebarActive] = useState(false);

  const [login, setLogin] = useState(false);

  const { auth, setAuth } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open1, setOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open1);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const logout = () => {
    localStorage.clear("token_web");
    localStorage.clear("auth_user");
    setAuth(null);

    window.location.reload();
  };

  const SidebarFunc = () => {
    Sidebar ? setSidebarActive(false) : setSidebarActive(true);
  };

  const shoot = () => {
    window.location.href = "http://seller.rasayankart.in/";
  };

  const navigate = useNavigate();

  const token = localStorage.getItem("token_web");
  
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <nav className="dashboard-nav">
        <div className="df aic">
          <div onClick={SidebarFunc} className="nav-slider-btn">
            <MenuIcon className="slider-btn" />
          </div>
          <Link to="/" className="nav-brand">
            <img src={Rklogo} className="rklogo" alt="" />
            <span>Rasayan</span>Kart
          </Link>
        </div>

        <SearchBar />
        <div className="nav-spans">
          <Link to="/rfqstep1">Request For Quote</Link>
          <Link to="/shop">Buy</Link>
          <a
            href="http://seller.rasayankart.in/"
            target="_blank"
          >
            Sell
          </a>
        </div>
        <div className="nav-corner">
          <Link to="/cart">
            <img src={Bag} className="bag-icon" alt="" />
          </Link>
          <div className="nav-dropdown">
            <button className="basic-button-1" id="basic-button">
              <PersonIcon
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              />
              <img className="vs-img" src={Vs} alt="" />
              <Backdrop
                sx={{ color: "", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open1}
                onClick={handleClose}
              >
                <Menu
                  id="basic-menu"
                  className="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <Link to="/rfqstep1" className="nav-list">
                    <strong>Create RFQ</strong>
                  </Link>
                  <Link to="/orders" className="nav-list">
                    My Orders
                  </Link>
                  <Link to="/wallet" className="nav-list">
                    RK Wallet
                  </Link>
                  <Link to="/account" className="nav-list">
                    My Account
                  </Link>
                  <button className="nav-list" onClick={logout}>
                    Logout
                  </button>
                </Menu>
              </Backdrop>
              <KeyboardArrowDownIcon
                className="basic-menu-dd"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              />
            </button>
            <Link
              style={{ position: "relative", padding: "5px" }}
              to="/busers"
              className="basic-button-2"
            >
              <PersonIcon />
              <img className="vs-img" src={Vs} alt="" />
            </Link>
          </div>
        </div>

        {/* SIDEBAR */}

        <div
          className={
            Sidebar === true
              ? "nav-sidebar df fdc nav-sidebar-active"
              : "nav-sidebar df fdc "
          }
        >
          <div className="nav-sidebar-header df aic jcb">
            <Link to="/" className="nav-brand">
              <img src={Rklogo} className="rklogo-sidebar" alt="" />
              <span>Rasayan</span>Kart
            </Link>
            <div onClick={SidebarFunc} className="nav-slider-btn">
              <CloseIcon className="slider-btn" />
            </div>
          </div>

          <div className="nav-sidebar-body">
            <Button
              to={"/shop"}
              title="Buy on rasayan kart"
              logo={<ShoppingCartOutlinedIcon className="nsb-icon" />}
              val={1}
            />
            <Button
              to={"/seller"}
              title="Sell on rasayan kart"
              logo={<StoreIcon className="nsb-icon" />}
            />
            <Button
              to={"/cart"}
              title="My Cart"
              logo={<LocalMallIcon className="nsb-icon" />}
            />

            <div className="nsb-heading">Buyer's Profile</div>
            <div className="line-nsb"></div>

            <Button
              to={"/busers"}
              title="My Account"
              logo={<StoreIcon className="nsb-icon" />}
            />
            <Button
              to={"/bw"}
              title="Wallet"
              logo={<Wallet className="nsb-icon" />}
            />
            <Button
              to={"/bd"}
              title="Documents"
              logo={<InsertDriveFile className="nsb-icon" />}
            />
            <div className="nsb-heading">My Orders</div>
            <div className="line-nsb"></div>
            <Button
              to={"/bh"}
              title="Confirmed"
              logo={<CalendarMonthIcon className="nsb-icon" />}
            />
            <Button
              to={"/br"}
              title="Pending"
              logo={<TextSnippet className="nsb-icon" />}
            />

            <div className="line-nsb"></div>

            <Button
              to={""}
              title="Help Center"
              logo={<InfoIcon className="nsb-icon" />}
            />
            <Button
              to={""}
              title="Policy"
              logo={<LightbulbIcon className="nsb-icon" />}
            />
            <Button
              to={""}
              title="Notifications"
              logo={<Email className="nsb-icon" />}
            />
            <Button
              to={"/"}
              title="Logout"
              logo={<LogoutIcon className="nsb-icon" />}
            />
            <div className="hehe-div">
              <FormGroup onClick={shoot} className="df">
                {/* <FormControlLabel
                                className='hehe-form'

                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label="Seller"
                            /> */}

                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Buyer</Typography>
                  <AntSwitch
                    defaultChecked
                    inputProps={{ "aria-label": "ant design" }}
                  />
                  <Typography>Seller</Typography>
                </Stack>
              </FormGroup>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

function Navbar() {
  const [Sidebar, setSidebarActive] = useState(false);

  const [login, setLogin] = useState(false);

  const { auth, setAuth } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open1, setOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open1);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const logout = () => {
    localStorage.clear("token_web");
    localStorage.clear("auth_user");
    setAuth(null);

    window.location.reload();
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const shoot = () => {
    window.location.href = "http://seller.rasayankart.in/";
  };

  const checkLogin = () => {
    const isLogin = localStorage.getItem("token_web");
    if (isLogin) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  return login ? <NavbarAuth /> : <NavbarLogin />;
}

export default Navbar;

export { NavbarAuth };
