import React from "react";
import Checkbox from "@mui/material/Checkbox";
const AllCities = ({ CityData, applyCity }) => { 
  return (
    <>
      {CityData.map((item, index) => {
        return (
          <div className="accordian-form" key={index}>
            <Checkbox
              onClick={(e) => {
                applyCity(item.state)
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
            <label htmlFor="">{item.state}</label>
          </div>
        );
      })}
    </>
  );
};

export default AllCities;
