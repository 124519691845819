import "../Tabs/tabs.css";
import "./productdesc.css";
import { useState } from "react";
import Pdf from "../Pdf/Pdf";
import Product from "../Product/Product";
import ProductImg from "../../../../assets/product.png";
import Sellerbanner from "../../../../assets/sellerbanner.png";
import Conbanner from "../../../../assets/contactbanner.png";
import Newpdf from "../Pdf/Newpdf";
import { Link } from "react-router-dom";
function ProductDesc({ documents, about }) {
  const [toggleState, setToggleState] = useState(2);
  const toggleTab = (index) => {
    setToggleState(index);
  };


  return (
    <div className="seller-tabs-content-container pdesc-container">
      <div className="seller-tab-btn-container">
        <div className="df stbc-c">
          {/* <div onClick={() => { toggleTab(1) }} className={toggleState === 1 ? "seller-tab-btn tab-active-pdesc" : "seller-tab-btn"}>Description</div> */}
          <div
            onClick={() => {
              toggleTab(2);
            }}
            className={
              toggleState === 2
                ? "seller-tab-btn tab-active-pdesc"
                : "seller-tab-btn"
            }
          >
            Documents
          </div>
          <div
            onClick={() => {
              toggleTab(3);
            }}
            className={
              toggleState === 3
                ? "seller-tab-btn tab-active-pdesc"
                : "seller-tab-btn"
            }
          >
            About us
          </div>
          <div
            onClick={() => {
              toggleTab(4);
            }}
            className={
              toggleState === 4
                ? "seller-tab-btn tab-active-pdesc"
                : "seller-tab-btn"
            }
          >
            Reviews
          </div>
        </div>
      </div>

      <div className="seller-content-container">
        <div
          className={
            toggleState === 2
              ? "seller-content content-active"
              : "seller-content"
          }
        >
          <div className="w8-div">
            <div className="pdf-container">
              {documents.map((item) => {
                return (
                  <Newpdf
                    url={item.url}
                    name={item.entity || 'Product'}
                    size={"Download"}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div
          className={
            toggleState === 3
              ? "seller-content content-active"
              : "seller-content"
          }
        >
          <div className="w8-div">
            <div className="seller-det">
              <div className="seller-det-left">Address</div>
              <div className="seller-det-right text-capitalize">
                {about?.vendor.address} {about?.vendor.zip || "------"}
              </div>
            </div>
            <div className="seller-det">
              <div className="seller-det-left">About Company</div>
              <div className="seller-det-right">
                {about?.vendor.about_company || "------"}
              </div>
            </div>

            {about?.id && (
              <div className="seller-btn-c df aic jcc">
                <Link
                  to={`/seller/${about?.id || ""}?tab=2`}
                  className="conbtn-g"
                >
                  Contact Form
                </Link>
              </div>
            )}
          </div>
        </div>
        <div
          className={
            toggleState === 4
              ? "seller-content content-active"
              : "seller-content"
          }
        >
          <h1>No reviwes </h1>
        </div>
      </div>
    </div>
  );
}

export default ProductDesc;
