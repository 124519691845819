import { useState } from "react";
import { Link } from "react-router-dom";
import PopupLogin from "../dashboard/Login/PopupLogin";
import CloseIcon from "@mui/icons-material/Close";
import PopupSignup from "../dashboard/Login/PopupSignup";
import PopupNumber from "../dashboard/Login/PopupNumber";
import PopupOtp from "../dashboard/Login/PopupOtp";
import LogoImg from "../../assets/rklogo.png";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MenuIcon from "@mui/icons-material/Menu";

import StoreIcon from "@mui/icons-material/Store";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import InfoIcon from "@mui/icons-material/Info";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import EmailIcon from "@mui/icons-material/Email";
import LogoutIcon from "@mui/icons-material/Logout";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

// import LoginS from "./LoginS";

function Button(props) {
  return (
    <Link
      to={props.to}
      className={
        props.val === true ? "sidebar-spl-btn active-btn" : "sidebar-spl-btn"
      }
      onClick={props.func}
    >
      {props.logo}
      {props.title}
    </Link>
  );
}
function ButtonR(props) {
  return (
    <a
      href={props.to}
      className={
        props.val === true ? "sidebar-spl-btn active-btn" : "sidebar-spl-btn"
      }
      onClick={props.func}
    >
      {props.logo}
      {props.title}
    </a>
  );
}

function Navbar() {
  const [isSignup, setSignup] = useState(0);

  function No(index) {
    setSignup(index);
  }

  const [Sidebar, setSidebarActive] = useState(false);

  const SidebarFunc = () => {
    Sidebar ? setSidebarActive(false) : setSidebarActive(true);
  };

  return (
    <nav className="navbar">
      <Link to="/" className="logo">
        <img src={LogoImg} alt="" />
        <span>Rasayan</span> Kart
      </Link>
      <div style={{ display: "flex" }}>
        <div className="menu">
          <ul>
            <Link to="/rfqstep1" className="nav-links">Request For Quote</Link>

            <Link to="/shop" className="nav-links active">
              Buy
            </Link>
            <a
              href="http://seller.rasayankart.in/"
              target="_blank"
              className="nav-links"
            >
              Sell
            </a>
            <Link to="/about-us" className="nav-links">
              Company
            </Link>
            <Link to={"/login"} className="nav-links">
              Login
            </Link>
            {/* <Link to='/shop' className="nav-links">Market</Link> */}
          </ul>
        </div>
        <div className="nav-btns">
          {/* <button className="navBtn-1">Login</button> */}
          <button className="navBtn-2" style={{ marginTop: "6px" }}>
            <Link to={"/register"} style={{ color: "#fff" }}>
              Register
            </Link>
          </button>
        </div>
      </div>

      <button className="toggle-btn" onClick={SidebarFunc}>
        <MenuIcon className="slider-btn" />
      </button>

      <div className={isSignup === 1 ? "popup-c active-popup" : "popup-c"}>
        <div className="login-signup-overlay"></div>
        <div className="login-signup-box">
          <CloseIcon className="popup-i" onClick={() => No(0)} />
          <PopupLogin func={() => No(3)} />
        </div>
      </div>
      <div className={isSignup === 2 ? "popup-c active-popup" : "popup-c"}>
        <div className="login-signup-overlay"></div>
        <div className="login-signup-box">
          <CloseIcon className="popup-i" onClick={() => No(0)} />
          <PopupSignup func1={() => No(3)} func2={() => No(1)} />
        </div>
      </div>
      <div className={isSignup === 3 ? "popup-c active-popup" : "popup-c"}>
        <div className="login-signup-overlay"></div>
        <div className="login-signup-box">
          <CloseIcon className="popup-i" onClick={() => No(0)} />
          <PopupNumber func1={() => No(4)} func2={() => No(1)} />
        </div>
      </div>
      <div className={isSignup === 4 ? "popup-c active-popup" : "popup-c"}>
        <div className="login-signup-overlay"></div>
        <div className="login-signup-box">
          <CloseIcon className="popup-i" onClick={() => No(0)} />
          <PopupOtp func1={() => No(2)} func2={() => No(1)} />
        </div>
      </div>

      <div
        className={
          Sidebar === true
            ? "nav-sidebar df fdc nav-sidebar-active "
            : "nav-sidebar df fdc "
        }
      >
        <div className="nav-sidebar-header df aic jcb">
          <Link to="/" className="nav-brand">
            <img src={LogoImg} className="rklogo-sidebar" alt="" />
            <span>Rasayan</span>Kart
          </Link>
          <div onClick={SidebarFunc} className="nav-slider-btn">
            <CloseIcon className="slider-btn" />
          </div>
        </div>

        <div className="nav-sidebar-body">
          <Button
            to={"/shop"}
            title="Buy on rasayankart"
            logo={<ShoppingCartOutlinedIcon className="nsb-icon" />}
          />
          <ButtonR
            to={"http://seller.rasayankart.in/"}
            title="Sell on rasayankart"
            logo={<StoreIcon className="nsb-icon" />}
          />
          <Button
            to={"/contact-us"}
            title="Contact us"
            logo={<ExitToAppIcon className="nsb-icon" />}
          />
          <Button
            to={"/about-us"}
            title="About us"
            logo={<InfoIcon className="nsb-icon" />}
          />
          <Button
            to={"/checkout"}
            title="my cart"
            logo={<LocalMallIcon className="nsb-icon" />}
          />
          <Button
            to={"/login"}
            title="Login"
            logo={<LogoutIcon className="nsb-icon" />}
          />
          <Button
            to={"/register"}
            title="Signup"
            logo={<ExitToAppIcon className="nsb-icon" />}
          />
        </div>

        {/* <ul className="landing-sidebar-ul">
                    <Link to='/bp' className="lul-li">Buy</Link>
                    <Link to='/seller' className="lul-li">Sell</Link>
                    <li className="lul-li">market</li>
                    <li onClick={() => No(1)} className="lul-li">Login</li>
                    <li onClick={() => No(3)} className="lul-li">Sign up</li>
                </ul> */}
      </div>
    </nav>
  );
}

export default Navbar;
