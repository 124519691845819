import React from "react";
import "./landing.css";
import "./sidebar.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { NavbarAuth } from "../dashboard/Components/Navbar/Navbar";

const Privacy = () => {
  const token = localStorage.getItem("token_web");

  return (
    <>
      <section className="landing-sec-2New">
      {token ? <NavbarAuth /> : <Navbar />}

        <div className="contactUsOuter">
          <div className="contactUsInner">
            <div className="ConatctleftSec">
              <h2>PRIVACY POLICY</h2>
            </div>
          </div>
        </div>
      </section>

      <div
        className="ContactContentSection aboutus"
        style={{ position: "relative" }}
      >
        <div className="ContactContentSectionRight aboutus">
          <div className="aboutUsSectionOter">
            <ol>
              <li>
                <p class="fw-bold">PERSONAL INFORMATION HANDLING</p>
                <ul>
                  <li>
                    <p>
                      User-provided data on the website is governed by
                      Rasayankart’s Privacy Policy. Please refer to our Privacy
                      Policy for details.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p class="fw-bold">PRIVACY POLICY</p>
                <ul>
                  <li>
                    <p>
                      Our Privacy Policy governs the collection, possession,
                      storage, and handling of user information, including
                      personal and sensitive data, on www.rasayankart.in
                      (referred to as the "site"). We are committed to
                      safeguarding your privacy and ensuring the protection of
                      your personal identifiable information.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p class="fw-bold">ACCEPTANCE OF POLICY</p>
                <ul>
                  <li>
                    <p>
                      To use our site, you must accept this Privacy Policy. It
                      applies to all users, including those accessing the site
                      via mobile applications or similar devices.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p class="fw-bold">COLLECTION OF INFORMATION</p>
                <ul>
                  <li>
                    <p>
                      We collect information necessary for our services,
                      including:
                    </p>
                    <ul>
                      <li>
                        User registration details (name, company, email, phone
                        number, address).
                      </li>
                      <li>
                        Business information, including statutory details and
                        tax registration numbers.
                      </li>
                      <li>
                        Usage statistics (IP addresses, pages viewed, user
                        behaviour patterns) for analysis.
                      </li>
                      <li>
                        Information related to paid services, including
                        sensitive data like bank account numbers.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      <strong>User Registration:</strong> Once registered, you
                      are no longer anonymous. Your information is stored to
                      provide requested services and comply with legal
                      requirements.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Updating Information:</strong> You can update your
                      information through your site account or by contacting us.
                      Changes may take time due to verification processes.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Deactivation or Deletion:</strong> Users can
                      deactivate or delete their accounts. We evaluate such
                      requests case by case, but archived information may be
                      retained for legal compliance.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Purpose of Information:</strong> We collect
                      information for various purposes, including:
                    </p>
                    <ul>
                      <li>Identity verification and customized services</li>
                      <li>Facilitating site services</li>
                      <li>Advertising and marketing.</li>
                      <li>
                        Communication, business opportunities, and trade leads.
                      </li>
                      <li>
                        Sending communications, newsletters, and customized
                        mailers.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      <strong>Disclosure of Information:</strong> We may share
                      collected information with:
                    </p>
                    <ul>
                      <li>
                        Affiliated companies for efficiency and personalized
                        services.
                      </li>
                      <li>
                        Government or regulatory agencies as required by law.
                      </li>
                      <li>
                        Service providers for facilitating user requirements.
                      </li>
                      <li>
                        Business partners for offering their business offers
                        independently.
                      </li>
                    </ul>
                    <p>
                      Please note that linked websites have their own Privacy
                      Policies.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p class="fw-bold">PROTECTION OF INFORMATION</p>
                <ul>
                  <li>
                    <p>
                      We employ security measures but cannot guarantee 100%
                      security. We recommend not sharing sensitive credentials
                      with our employees or others.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p class="fw-bold">COOKIES</p>
                <ul>
                  <li>
                    <p>
                      We and third parties may use cookies and similar
                      technologies. They help us recognize your browser software
                      and provide features like recommendations and
                      personalization.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p class="fw-bold">DATA COLLECTION FOR CHILDREN</p>
                <ul>
                  <li>
                    <p>
                      We do not knowingly collect or maintain Personally
                      Identifiable Information from individuals under 18 years
                      of age.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p class="fw-bold">Data Transfers</p>
                <ul>
                  <li>
                    <p>
                      Your data may be transferred to our affiliates, partners,
                      or service providers, including those outside your country
                      of residence. We ensure data protection during such
                      transfers.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p class="fw-bold">Questions</p>
                <ul>
                  <li>
                    <p>
                      For questions, clarifications, or grievances, please email
                      us at info@rasayankart.in
                    </p>
                  </li>
                </ul>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
