import React from "react";
import "./landing.css";
import "./sidebar.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { NavbarAuth } from "../dashboard/Components/Navbar/Navbar";

const Faq = () => {
  const token = localStorage.getItem("token_web");

  return (
    <>
      <section className="landing-sec-2New">
          {token ? <NavbarAuth /> : <Navbar />}


        <div className="contactUsOuter">
          <div className="contactUsInner">
            <div className="ConatctleftSec">
              <h2>FAQs</h2>
              <p>Answers to common questions & issues.</p>
            </div>
          </div>
        </div>
      </section>

      <div
        className="ContactContentSection aboutus"
        style={{ position: "relative" }}
      >
        <div className="ContactContentSectionLeft">
          <h3>Frequently Asked Questions</h3>
        </div>

        <div className="ContactContentSectionRight aboutus">
          <div className="aboutUsSectionOter">
            <h3 style={{ fontSize: "30px", color: "#006ff2" }}>
              Buyer’s Portal
            </h3>

            <ol>
              <li>
                <p className="fw-bold">
                  How to create account on Rasayan Kart?
                </p>
                <p>
                  You can create a new account by visiting :
                  https://rasayankart.in/#/register
                </p>
              </li>
              <li>
                <p className="fw-bold">
                  Where do I file complaints related to trade dispute?
                </p>
                <p>
                  You can lodge complaints by visiting :
                  https://rasayankart.in/#/support ( Click on Create Ticket)
                </p>
              </li>
              <li>
                <p className="fw-bold">
                  What is customer support contact details of Rasayan Kart?
                </p>
                <p>Customer Support Contact Details : info@rasayankart.in</p>
              </li>
              <li>
                <p className="fw-bold">
                  How to view/track my order/consignment on portal?
                </p>
                <p>
                  You can track order status by logging in to your account on
                  the Buyer Portal: Login to your portal {">"} Go to My Account{" "}
                  {">"} Go to My Orders
                </p>
              </li>
              <li>
                <p className="fw-bold">
                  Does Rasayan Kart take responsibility for the quality of
                  products OR any fraudant nature of supply?{" "}
                </p>
                <p>
                  No, Rasayan Kart does not take responsibility for quality of
                  products.
                </p>
              </li>
              <li>
                <p className="fw-bold">
                  How does buyer can rate / provide feedback to a seller listed
                  on Rasayan Kart platform?
                </p>
                <p>
                  Through the customer feedback it's available for all customers
                  for vendors and products
                </p>
              </li>
              <li>
                <p className="fw-bold">
                  How to get the prices for my requirements?
                </p>
                <p>
                  Click on “Create RFQ” for the products you want, Enter your
                  price and platform will allow you to negotiate with seller/s.{" "}
                </p>
              </li>
              <li>
                <p className="fw-bold">What raw materials do you supply?</p>
                <p>
                  To check our product catalog please visit:
                  https://rasayankart.in/#/shop
                </p>
              </li>
              <li>
                <p className="fw-bold">What all brands do you supply?</p>
                <p>
                  We have associated with all the major brands in each raw
                  material category. For more details check out our product
                  catalog.
                </p>
              </li>
              <li>
                <p className="fw-bold">
                  Where do you supply the raw materials in India?
                </p>
                <p>We have our presence and cater our service Pan-India.</p>
              </li>

              <li>
                <p className="fw-bold">
                  When will I get the delivery after placing the order?
                </p>
                <p>
                  The delivery time will be communicated at the time of
                  providing quotation as it varies according to product category
                  and delivery location.
                </p>
              </li>
              <li>
                <p className="fw-bold">
                  How to raise a Request for Quotation ?
                </p>
                <p>
                  Creating RFQ is a very simple process.You can choose the
                  products you require from our product catalog and add them to
                  your cart. Under the “My Cart” section, you can add further
                  details to the items selected and simply click on “Create
                  RFQ”.
                </p>
              </li>
              <li>
                <p className="fw-bold">Can I Modify my created RFQ ?</p>
                <p>
                  You can log in to the Buyer Portal and add / update / delete
                  the items present in your raised RFQ. Please be mindful that
                  the turnaround time is increased on multiple changes in the
                  RFQ. You can also inform the changes in the RFQ to seller/our
                  admin who will be getting in touch with you after you raise
                  the RFQ.
                </p>
              </li>
            </ol>
          </div>
          {/* end of aboutUsSectionOter */}

          <div className="aboutUsSectionOter">
            <h3 style={{ fontSize: "30px", color: "#006ff2" }}>
              Seller’s Portal
            </h3>

            <ol>
              <li>
                <p className="fw-bold">
                  How do I register for paid membership on Rasayan Kart?
                </p>
                <p>Soon this feature will be available. It is in Process. </p>
              </li>
              <li>
                <p className="fw-bold">
                  How to create account on Rasayan Kart?{" "}
                </p>
                <p>
                  You can create a new account by visiting :{" "}
                  <a href="https://rasayankart.in/#/register" target="_blank">
                    https://rasayankart.in/#/register
                  </a>
                </p>
              </li>
              <li>
                <p className="fw-bold">How to sell items on Rasayan Kart?</p>
                <p>
                  Register your company on Rasayan Kart portal, upon successful
                  verification you can create product catalogue and start
                  selling your products.
                </p>
              </li>
              <li>
                <p className="fw-bold">How to create product catalogue?</p>
                <p>
                  You can create product catalogue by visiting :{" "}
                  <a
                    href="https://seller.rasayankart.in/product/add"
                    target="_blank"
                  >
                    https://seller.rasayankart.in/product/add
                  </a>
                </p>
              </li>
            </ol>

            <h3 style={{ fontSize: "30px", color: "#006ff2" }}>
              Payment Portal
            </h3>
            <ol>
              <li>
                <p className="fw-bold">How to Apply for Credit ?</p>
                <p>
                  You can apply for credit by filling the application form here:{" "}
                  <a href="https://seller.rasayankart.in/loan" target="_blank">
                    https://seller.rasayankart.in/loan
                  </a>
                </p>
              </li>
              <li>
                <p className="fw-bold">How much Credit can I get ?</p>
                <p>
                  You can get Collateral Free Credit depending on the credit
                  worthiness and determined solely on the discretion of the
                  rasayankart team
                </p>
              </li>
              <li>
                <p className="fw-bold">
                  Do you also provide secured credit / Trade insurance?
                </p>
                <p>Yes, you can also purchase raw material with LC and BG</p>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
