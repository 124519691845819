import "./filter.css";
// Icons
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CategoryIcon from "@mui/icons-material/Category";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import StoreIcon from "@mui/icons-material/Store";
import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";

import CategoryData from "./CategoryApi";
import AllCategories from "./AllCategories";
import CitiesData from "./CitiesApi";
import AllCities from "./AllCities";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
function valuetext(value) {
  return `${value}°C`;
}

export default function Filter({
  category,
  cities,
  applyCategory,
  applyCity,
  setPrice,
}) {
  const [value, setValue] = React.useState([0, 200000]);

  const handleChangeNew = (event, newValue) => {
    setValue(newValue)
    try {
      setPrice({
        from_price: newValue[0],
        to_price: newValue[1],
      });
    } catch (error) {
      setPrice({
        from_price: "",
        to_price: "",
      });
    }
  };

  const [activeMenu, setActiveMenu] = useState("");

  return (
    <>
      <h1 className="filter-heading">Filters</h1>

      {/* #1 Dropdown */}
      <div className="filterOterSection">
        <div className="accordian">
          {/* Here is button */}
          {/* <LocationOnIcon className='btn-icons' fontSize="small" /> */}
          <button onClick={() => setActiveMenu("")} className="accordian-btn">
            <div>Cities </div>{" "}
            <ExpandLessIcon className={activeMenu === "" ? "" : "rotated"} />
          </button>

          {/* content of DropDown */}
          <div
            className={
              activeMenu === "" ? "accordian-data " : "accordian-data fold"
            }
          >
            <div className="ad-con">
              <AllCities CityData={cities} applyCity={applyCity} />
            </div>
          </div>
        </div>

        {/* #2 Dropdown */}
        <div className="accordian">
          {/* Here is button */}
          {/* <CategoryIcon className='btn-icons' fontSize="small" /> */}
          <button onClick={() => setActiveMenu("")} className="accordian-btn">
            <div>Categories </div>{" "}
            <ExpandLessIcon
              className={activeMenu === "category" ? "" : "rotated"}
            />{" "}
          </button>

          {/* content of DropDown */}
          <div
            className={
              activeMenu === "" ? "accordian-data " : "accordian-data fold"
            }
          >
            <div className="ad-con">
              <AllCategories
                CateData={category}
                applyCategory={applyCategory}
              />
            </div>
          </div>
        </div>

        {/* #3 Dropdown */}
        <div className="accordian">
          {/* Here is button */}
          {/* <StoreIcon className='btn-icons' fontSize="small" /> */}
          <button onClick={() => setActiveMenu("")} className="accordian-btn">
            <div>Price </div>{" "}
            <ExpandLessIcon
              className={activeMenu === "seller" ? "" : "rotated"}
            />{" "}
          </button>

          {/* content of DropDown */}
          <div
            className={
              activeMenu === ""
                ? "accordian-data accordian-data2"
                : "accordian-data fold"
            }
          >
            <div
              className="ad-con"
              style={{ padding: "10px", overflow: "unset", height: "50px" }}
            >
              <Box>
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={value}
                  min={0}
                  step={500}
                  max={300000}
                  onChange={handleChangeNew}
                  // valueLabelDisplay="auto"
                  valueLabelDisplay="on"
                  getAriaValueText={valuetext}
                />
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
