import "./login.css";
import { useContext } from "react";
import BlueBox from "../Components/BlueBox/BlueBox";
import LoginImg from "../../../assets/login.png";
import { Link } from "react-router-dom";
import { baseUrl } from "../../../utils/baseUrl";
import { useState } from "react";
import { toast } from "react-toastify";
import { toasterUpdate } from "../../../utils/toasterUpdate";
// import { useState } from 'react';
import { AuthContext } from "../../../context/AuthContext";

function PopupLogin(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { auth, setAuth } = useContext(AuthContext);

  const login = async (e) => {
    e.preventDefault();
    const toastID = toast.loading("Processing...");

    const response = await fetch(`${baseUrl}/login`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    toasterUpdate(toastID, result.message, "success");

    const token = localStorage.setItem(
      "token_web",
      JSON.stringify(result.data.token)
    );
    const user = localStorage.setItem(
      "auth_user",
      JSON.stringify(result.data.user)
    );

    setAuth(() => {
      return { user, token };
    });

    window.location.reload();
  };

  return (
    <>
      <BlueBox
        img={LoginImg}
        hd="Reduce Costs Increase Profits"
        p="Join the Chemarc Trade Network to Buy and/or Sell chemicals online"
      />

      <div className="regi-form-right">
        <form onSubmit={login}>
          <div>
            <h1>Login</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Laudantium, aliquam!
            </p>
          </div>

          <div>
            <div className="regi-input-c">
              <input
                type="email"
                value={email}
                onInput={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="email"
                required
              />
            </div>
            <div className="regi-input-c">
              <input
                type="password"
                value={password}
                onInput={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder="password"
                required
              />
              <div className="blue-txt">Forget Password?</div>
            </div>
          </div>

          <div>
            <button type="submit" className="button">
              Sign in
            </button>
            <div className="bottom-txt">New to Rasyan Cart</div>
            <div onClick={props.func} className="blue-txt">
              Register Now
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default PopupLogin;
