import "./pdf.css";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FileCopy } from "@mui/icons-material";

function Newpdf({ url, name, size = 0 }) {
  return (
    <div className="pdf-card new-pdf-card">
      <div className="pdf-header new-pdf-header">
        <FileCopy fontSize="large" />
      </div>
      <div className="pdf-details new-pdf-details">
        <div>
          <div className="new-pdf-name">{name}</div>
          {/* <div className="new-pdf-size">{size}</div> */}
        </div>
        <a href={url} target="_blank">
          <FileDownloadOutlinedIcon className="download-icon" />
        </a>
      </div>
    </div>
  );
}

export default Newpdf;
