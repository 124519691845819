import * as React from "react";

import "./navfilters.css";
import ClearIcon from "@mui/icons-material/Clear";
import SortIcon from "@mui/icons-material/Sort";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Navfilters({ count,applySort }) {
  return (
    <div className="navfilters">
      <div className="navfilters-top">
        <div className="navfilters-right">
          <div className="navfilters-heading">
            Sort by <SortIcon />
          </div>
          <div className="navfilters-dropdown-btn">
            {/* <button>Relevence <ExpandMoreIcon/></button> */}
            <select name="sort" id="sort" className="form-control" onChange={(e) => applySort(e.target.value)}>
            <option value="lowtohigh">Price Low to High</option>
              <option value="hightolow">Price High to Low</option>
              <option value="newest">Newest First</option>
              <option value="oldest">Oldest First</option>
            </select>
          </div>
        </div>
      </div>
      <div className="navfilters-down">
        <div className="navfilters-heading">Results</div>
        <div className="navfilters-down-result">{`${count.from || 0}-${
          count.to || 0
        }`}</div>
      </div>
    </div>
  );
}

export default Navfilters;
