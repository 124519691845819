import React from "react";
import Checkbox from "@mui/material/Checkbox";
const AllCategories = ({ CateData, applyCategory }) => {
  return (
    CateData?.map((item, index) => {
      return (
        <div className="accordian-form" key={index}>
          <Checkbox
            onClick={(e) => {
              applyCategory(item.id)
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
          <label htmlFor={item.slug}>{item.slug}</label>
        </div>
      );
    })
  );
};

export default AllCategories;
