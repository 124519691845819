import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import { toasterUpdate } from "../../utils/toasterUpdate";
import { baseUrl } from "../../utils/baseUrl";

function Subscribe() {
  const formRef = useRef(null);
  const [email, setEmail] = useState("");

  const createApi = async (e) => {
    try {
      e.preventDefault();

      const toastID = toast.loading("Creating...");

      const token = localStorage.getItem("token_web");
      const response = await fetch(`${baseUrl}/subscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ email }),
      });

      const result = await response.json();

      if (result.error) {
        toasterUpdate(toastID, result.message, "error");
        return;
      }
      formRef.current.reset();
      toasterUpdate(toastID, result.message, "success");
    } catch (error) {
      toast.error("Something Technical Error");
    }
  };

  return (
    <section className="landing-sec-7">
      <h1>Subscribe to our news</h1>
      <p>Stay ahead with our news letter subscription.</p>
      <form onSubmit={createApi} ref={formRef}>
        <input
          type="email"
          id="email"
          onInput={(e) => setEmail(e.target.value)}
          required
          placeholder="email address"
        />
        <button type="submit">Send</button>
      </form>
    </section>
  );
}

export default Subscribe;
